<template>
  <div>
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10">
        <h3 class="font-size-h1">Autenticação</h3>
        <p class="text-muted font-weight-semi-bold">
          Informe suas credenciais para acessar o sistema.
        </p>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="autenticar">
        <b-form-group label="Usuário" label-for="credenciais-usuario">
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="credenciais-usuario"
            name="usuario"
            v-model="credenciais.usuario"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Senha" label-for="credenciais-senha">
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="credenciais-senha"
            name="senha"
            v-model="credenciais.senha"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
        </b-form-group>

        <!--begin::Action-->
        <div
          class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <a
            href="#"
            class="text-muted text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Esqueceu sua senha?
          </a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-elevate btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Autenticar
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}

.login-form .login-signin {
  width: 100% !important;
  max-width: 450px !important;
}
</style>

<script>
import AutenticacaoService from "@/core/services/autenticacao.service";
import { DADOS_AUTENTICACAO } from "@/core/services/store/autenticacao.module";

export default {
  name: "autenticar",
  data() {
    return {
      credenciais: {
        usuario: "",
        senha: "",
      },
    };
  },

  methods: {
    async autenticar() {
      await AutenticacaoService.autenticar(this.credenciais).then((res) => {
        this.$store
          .dispatch(DADOS_AUTENTICACAO, res.data)
          .then(() => this.$router.push({ name: "inicio" }));
      });
    },
  },
};
</script>
